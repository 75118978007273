import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

let darkt = false;
if (localStorage.getItem("dark")) {
   darkt = JSON.parse(localStorage.getItem("dark"));
}

export default new Vuetify({
   theme: {
      dark: darkt,
      themes: {
         light: {
            primary: "#00BFA5",
            secondary: "#424242",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
         },
         dark: {
            primary: "#00BFA5",
            secondary: "#424242",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
         },
      },
   },
});
