module.exports = Object.freeze({
   API_URL: "public/api",
   //API_URL: "http://localhost:80/api",
   FRONTEND_URL: "https://pideloyya4u.com/",
   VAPID_KEY: "BNJU8Yq3iUMa9sAYRHz3HAMzrKGl3YdefwGAR84LIfgO_EoGFHwj-kV7OujnoIg8R-zMlPZX4b8bFkut3LcG_f8",
   APP_NAME: "4U Pidelo y Ya!",
   DEVELOP_MODE: true,
   COMISIONES: [43, 45, 48, 50, 55],
   PERMISOS: {
      super: "super",
      usuarios_roles: "usuarios_roles",
      clientes: "clientes",
      crear_clientes: "crear_clientes",
      editar_clientes: "editar_clientes",
      eliminar_clientes: "eliminar_clientes",
      repartidores: "repartidores",
      crear_repartidores: "crear_repartidores",
      editar_repartidores: "editar_repartidores",
      empresas: "empresas",
      crear_empresas: "crear_empresas",
      editar_empresas: "editar_empresas",
      pedidos: "pedidos",
      crear_pedidos: "crear_pedidos",
      editar_pedidos: "editar_pedidos",
      eliminar_pedidos: "eliminar_pedidos",
      cierre_caja: "cierre_caja",
      entrada_caja: "entrada_caja",
      salida_caja: "salida_caja",
      recibir_pedidos: "recibir_pedidos",
      pagar_pedidos: "pagar_pedidos",
      editar_moviminetos: "editar_moviminetos",
      cancelar_movimientos: "cancelar_movimientos",
      verificar_pedidos: "verificar_pedidos",
      apertura_caja: "apertura_caja",
      cajas: "cajas",
      movimientos: "movimientos",
      reportes: "reportes",
      four_u: "four_u",
      caja_general: "caja_general",
      prestamo: "prestamo",
      pago_prestamo: "pago_prestamo",
      pago_restas: "pago_restas",
      fondos: "fondos",
      seccion_prestamos: "seccion_prestamos",
      seccion_prestamos_prestar: "seccion_prestamos_prestar",
      seccion_prestamos_cobrar: "seccion_prestamos_cobrar",
      seccion_prestamos_cancelar: "seccion_prestamos_cancelar",
   },
});