<template>
   <div>
      <v-dialog v-model="dialog" width="500px">
         <template v-slot:activator="{ on, attrs }">
            <v-text-field v-bind="attrs" v-on="on" :value="display" readonly outlined dense hide-details :label="label"></v-text-field>
         </template>
         <v-card>
            <v-date-picker v-model="local" range full-width></v-date-picker>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import moment from "moment";

export default {
   props: {
      value: {
         type: Array
      },
      label: {
         type: String,
         default: ""
      }
   },
   data: () => {
      return {
         dialog: false,
         local: [],
      };
   },
   created() {
      this.local = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
      this.$emit("input", this.local);
   },
   computed: {
      display() {
         if (this.local.length == 2) {
            if(this.local[0] == this.local[1]){
                if(this.local[0] == moment().format("YYYY-MM-DD")){
                    return "Hoy"
                }
                return this.local[0];
            }   
         }
         return this.local.join(" - ");
      },
   },
   watch: {
      value: function () {
         this.local = this.value;
      },
      dialog: function () {
         if (this.local.length == 2) {
            if (moment(this.local[0]).isAfter(moment(this.local[1]))) {
               this.local = [this.local[1], this.local[0]];
            }
         } else if (this.local.length == 1) {
            this.local = [this.local[0], this.local[0]];
         } else {
            this.local = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
         }
         this.$emit("input", this.local);
      },
   },
};
</script>

<style></style>
