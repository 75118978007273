<template>
   <div>
      <Notification type="error" message="Error" ref="error_noti" />
      <v-autocomplete
         v-model="empresa_model"
         :items="empresas"
         :loading="isloading"
         :search-input.sync="empresa_search"
         item-text="nombre"
         label="Buscar empresa"
         placeholder="Escribir nombre de la empresa"
         prepend-icon="mdi-store-search"
         return-object
         auto-select-first
         :hint="display_empresa"
         persistent-hint
      ></v-autocomplete>
   </div>
</template>

<script>
export default {
   props: {
      value: {
         type: Object,
      },
   },
   data: () => {
      return {
         empresa_model: null,
         empresa_search: "",
         empresas: [],
         isloading: false,
      };
   },
   created() {
      this.empresa_model = null;
      this.$emit("input", this.empresa_model);
   },
   methods: {
      do_empresa_search: async function () {
         if (this.isloading) return;
         if (!this.empresa_search) return;
         try {
            this.isloading = true;
            await this.$store.dispatch("getEmpresasAutocomplete", {
               search: this.empresa_search,
            });
            this.empresas = this.$store.state.empresas_autocomplete;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      get_default_item: async function (item) {
         this.$nextTick(async () => {
            try {
               this.isloading = true;
               await this.$store.dispatch("getEmpresasAutocomplete", {
                  search: item.nombre,
               });
               this.empresas = this.$store.state.empresas_autocomplete;
               if (this.empresas[0].empresa_id == item.empresa_id) {
                  this.empresa_model = this.empresas[0];
               }
            } catch (error) {
               this.$refs.error_noti.ShowPersistent(error);
            } finally {
               this.isloading = false;
            }
         });
      },
   },
   watch: {
      empresa_search: function () {
         this.do_empresa_search();
      },
      value: function () {
         this.empresa_model = this.value;
      },
      empresa_model: function () {
         this.$emit("input", this.empresa_model);
      },
   },
   computed: {
      display_empresa() {
         if (this.empresa_model) {
            return `${this.empresa_model.direccion ?? ""}, ${this.empresa_model.telefono ?? ""}
               Comisión porcentaje: ${this.empresa_model.comision_porcentaje ?? ""}%
                        Comisión importe: $${this.empresa_model.comision_importe ?? ""}
                        Comisión cliente: $${this.empresa_model.comision_cliente ?? ""}`;
         }
         return "";
      },
   },
};
</script>

<style></style>
