<template>
   <v-app>
      <Header />
      <v-main>
         <router-view />
      </v-main>
      <Footer />
   </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
   name: "App",
   components: {
      Header,
      Footer,
   },
};
</script>

<style>
html {
   overflow-y: auto;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #757575;
}

::-webkit-scrollbar-thumb {
  background: #e7e7e7;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444444;
}
</style>
