<template>
   <div>
      <v-expansion-panels>
         <v-expansion-panel>
            <v-expansion-panel-header>Pedido</v-expansion-panel-header>
            <v-expansion-panel-content>
               <div class="d-flex">
                  <div class="my-title mr-2">Fecha Hora</div>
                  <div class="my-content">{{ item.fecha_hora }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Pagado</div>
                  <div class="my-content">
                     <v-icon small>{{ item.pagado == "S" ? "mdi-checkbox-marked-outline" : "mdi-checkbox-blank-outline"
                        }}</v-icon>
                  </div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Descripción</div>
                  <div class="my-content">{{ item.descripcion }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Verificado</div>
                  <div class="my-content">
                     <v-icon small>{{ item.verificado == "S" ? "mdi-checkbox-marked-outline" :
                        "mdi-checkbox-blank-outline" }}</v-icon>
                  </div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Recibido</div>
                  <div class="my-content">
                     <v-icon small>{{ item.recibido == "S" ? "mdi-checkbox-marked-outline" :
                        "mdi-checkbox-blank-outline" }}</v-icon>
                  </div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Entregado</div>
                  <div class="my-content">
                     <v-icon small>{{ item.entregado == "S" ? "mdi-checkbox-marked-outline" :
                        "mdi-checkbox-blank-outline" }}</v-icon>
                  </div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Eliminado</div>
                  <div class="my-content">
                     <v-icon small>{{ item.eliminado == "S" ? "mdi-checkbox-marked-outline" :
                        "mdi-checkbox-blank-outline" }}</v-icon>
                  </div>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
         <v-expansion-panel>
            <v-expansion-panel-header>Cliente</v-expansion-panel-header>
            <v-expansion-panel-content>
               <div class="d-flex">
                  <div class="my-title mr-2">Telefono</div>
                  <div class="my-content">{{ item.telefono }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Nombre</div>
                  <div class="my-content">{{ item.nombre }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Calle</div>
                  <div class="my-content">{{ item.calle }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Num Ext</div>
                  <div class="my-content">{{ item.num_ext }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Colonia</div>
                  <div class="my-content">{{ item.colonia }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Entre Calles</div>
                  <div class="my-content">{{ item.entre_calles }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Referencias</div>
                  <div class="my-content">{{ item.referencias }}</div>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
         <v-expansion-panel>
            <v-expansion-panel-header>Cálculos</v-expansion-panel-header>
            <v-expansion-panel-content>
               <div class="d-flex">
                  <div class="my-title mr-2">Importe</div>
                  <div class="my-content">{{ currency_format(item.importe) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Porcentaje</div>
                  <div class="my-content">{{ item.comision_porcentaje }} <v-icon small>mdi-percent</v-icon></div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Porcentaje Importe</div>
                  <div class="my-content">{{ currency_format(item.comision_porcentaje_importe) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Importe</div>
                  <div class="my-content">{{ currency_format(item.comision_importe) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Cliente</div>
                  <div class="my-content">{{ currency_format(item.comision_cliente) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Home</div>
                  <div class="my-content">{{ currency_format(item.comision_home) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Comisión Extra</div>
                  <div class="my-content">{{ currency_format(item.comision_extra) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Costo Extra</div>
                  <div class="my-content">{{ currency_format(item.costo_extra) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Total Empresa</div>
                  <div class="my-content">{{ currency_format(item.total_empresa) }}</div>
               </div>
               <div class="d-flex">
                  <div class="my-title mr-2">Total</div>
                  <div class="my-content">{{ currency_format(item.total) }}</div>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
         <v-expansion-panel
            v-if="item.repa_espera || item.repa_recogido || item.repa_entregado || item.repa_aceptado || item.repa_cancelado"
         >
            <v-expansion-panel-header>Repartidor</v-expansion-panel-header>
            <v-expansion-panel-content>
               <div
                  v-if="item.repa_espera"
                  class="d-flex"
               >
                  <div class="my-title mr-2">Esperando</div>
                  <div class="my-content">{{ display_fh(item.repa_espera) }}</div>
               </div>
               <div
                  v-if="item.repa_recogido"
                  class="d-flex"
               >
                  <div class="my-title mr-2">Recogido</div>
                  <div class="my-content">{{ display_fh(item.repa_recogido) }}</div>
               </div>
               <div
                  v-if="item.repa_entregado"
                  class="d-flex"
               >
                  <div class="my-title mr-2">Entregado</div>
                  <div class="my-content">{{ display_fh(item.repa_entregado) }}</div>
               </div>
               <div
                  v-if="item.repa_aceptado"
                  class="d-flex"
               >
                  <div class="my-title mr-2">Aceptado</div>
                  <div class="my-content">{{ display_fh(item.repa_aceptado) }}</div>
               </div>
               <div
                  v-if="item.repa_cancelado"
                  class="d-flex"
               >
                  <div class="my-title mr-2">Cancelado</div>
                  <div class="my-content">{{ display_fh(item.repa_cancelado) }}</div>
               </div>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
   </div>
</template>

<script>
import moment from 'moment';

export default {
   props: {
      item: {
         type: Object,
      },
   },
   methods: {
      currency_format(num) {
         var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
         });
         return formatter.format(num);
      },
      display_fh(fh) {
         return moment(fh).format("YYYY-MM-DD hh:mm a");
      }
   },
};
</script>

<style>
.my-title {
   font-weight: bold;
}

.my-content {
   font-weight: normal;
}
</style>
