<template>
   <div>
      <Notification type="error" message="Error" ref="error_noti" />
      <v-autocomplete
         v-model="repartidor_model"
         :items="repartidores"
         :loading="isloading"
         :search-input.sync="repartidor_search"
         item-text="nombre"
         label="Buscar repartidor"
         placeholder="Escribir nombre"
         prepend-icon="mdi-account-search"
         return-object
         auto-select-first
      ></v-autocomplete>
   </div>
</template>

<script>
export default {
   props: {
      value: {
         type: Object,
      },
   },
   data: () => {
      return {
         repartidor_model: null,
         repartidor_search: "",
         repartidores: [],
         isloading: false,
      };
   },
   created() {
      this.repartidor_model = null;
      this.$emit("input", this.repartidor_model);
   },
   methods: {
      do_repartidor_search: async function () {
         if (this.isloading) return;
         if (!this.repartidor_search) return;
         try {
            this.isloading = true;
            await this.$store.dispatch("getRepartidoresAutocomplete", {
               search: this.repartidor_search,
            });
            this.repartidores = this.$store.state.repartidores_autocomplete;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      get_default_item: async function (item) {
         this.$nextTick(async () => {
            try {
               if (item.repartidor_id) {
                  this.isloading = true;
                  await this.$store.dispatch("getRepartidoresAutocomplete", {
                     search: item.nombre,
                  });
                  this.repartidores = this.$store.state.repartidores_autocomplete;
                  if (this.repartidores[0].repartidor_id == item.repartidor_id) {
                     this.repartidor_model = this.repartidores[0];
                  }
               }
            } catch (error) {
               console.log(error);
               this.$refs.error_noti.ShowPersistent(error);
            } finally {
               this.isloading = false;
            }
         });
      },
   },
   watch: {
      repartidor_search: function () {
         this.do_repartidor_search();
      },
      value: function () {
         this.repartidor_model = this.value;
      },
      repartidor_model: function () {
         this.$emit("input", this.repartidor_model);
      },
   },
};
</script>

<style></style>
