import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import c from "./constant";

Vue.use(Vuex);

axios.defaults.baseURL = c.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const getCacheableState = () => {
   return {
      username: "",
      name: "",
      token: "",
      caja_cerrada: "",
      permisos: [],
   };
};
const getDefaultState = () => {
   return {
      userdata: getCacheableState(),
      dashboard: {},
      reporte: {},
      users: [],
      roles: [],
      permisos: [],
      repartidores_autocomplete: [],
      empresas_autocomplete: [],
      clientes_autocomplete: [],
      pedidos_repa: [],
      pedidos_empresa: [],
      caja_preview: {},
      clientes: {
         items: [],
         totalItems: 10,
         options: {},
      },
      repartidores: {
         items: [],
         totalItems: 10,
         options: {},
      },
      empresas: {
         items: [],
         totalItems: 10,
         options: {},
      },
      pedidos: {
         items: [],
         totalItems: 10,
         options: {},
      },
      cajas: {
         items: [],
         totalItems: 10,
         options: {},
      },
      movimientos: {
         items: [],
         totalItems: 10,
         options: {},
      },
      four_u: {
         items: [],
         totalItems: 10,
         options: {},
         balance: 0,
      },
      prestamos: {
         items: [],
         totalItems: 10,
         options: {},
      },
      caja_general: {
         items: [],
         totalItems: 10,
         options: {},
         balance: 0,
      },
      saldos_prestamos: {
         items: [],
         totalItems: 10,
         options: {},
      },
   };
};

export default new Vuex.Store({
   state: getDefaultState(),
   mutations: {
      initializeStore(state) {
         if (sessionStorage.getItem("userdata")) {
            let userdata = JSON.parse(sessionStorage.getItem("userdata"));
            state.userdata = userdata;
            axios.defaults.headers.common["API-KEY"] = state.userdata.token;
         }
      },
      logout() {
         this.replaceState(getDefaultState());
      },
      login(state, payload) {
         state.userdata = payload;
      },
      dashboard(state, payload) {
         state.dashboard = payload;
      },
      reporte(state, payload) {
         state.reporte = payload;
      },
      users(state, payload) {
         state.users = payload;
      },
      roles(state, payload) {
         state.roles = payload;
      },
      permisos(state, payload) {
         state.permisos = payload;
      },
      clientes(state, payload) {
         state.clientes = payload;
      },
      pedidos(state, payload) {
         state.pedidos = payload;
      },
      cajas(state, payload) {
         state.cajas = payload;
      },
      movimientos(state, payload) {
         state.movimientos = payload;
      },
      four_u(state, payload) {
         state.four_u = payload;
      },
      prestamos(state, payload) {
         state.prestamos = payload;
      },
      saldos_prestamos(state, payload) {
         state.saldos_prestamos = payload;
      },
      caja_general(state, payload) {
         state.caja_general = payload;
      },
      estatus_caja(state, payload) {
         state.userdata.caja_cerrada = payload.caja_cerrada;
      },
      caja_preview(state, payload) {
         state.caja_preview = payload;
      },
      repartidores(state, payload) {
         state.repartidores = payload;
      },
      empresas(state, payload) {
         state.empresas = payload;
      },
      repartidores_autocomplete(state, payload) {
         state.repartidores_autocomplete = payload;
      },
      empresas_autocomplete(state, payload) {
         state.empresas_autocomplete = payload;
      },
      clientes_autocomplete(state, payload) {
         state.clientes_autocomplete = payload;
      },
      pedidos_repa(state, payload) {
         state.pedidos_repa = payload;
      },
      pedidos_empresa(state, payload) {
         state.pedidos_empresa = payload;
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let res = await axios.post("login", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data);
            axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async login_repa(context, payload) {
         try {
            let res = await axios.post("repa/login", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data);
            axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async login_resta(context, payload) {
         try {
            let res = await axios.post("resta/login", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data);
            axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //GET
      async getDashboard(context) {
         try {
            let res = await axios.get("dashboard");
            context.commit("dashboard", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getReporte(context, payload) {
         try {
            let res = await axios.get("reportes", {
               params: payload
            });
            context.commit("reporte", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getUsers(context) {
         try {
            let res = await axios.get("users");
            context.commit("users", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRoles(context) {
         try {
            let res = await axios.get("roles");
            context.commit("roles", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPermisos(context) {
         try {
            let res = await axios.get("permisos");
            context.commit("permisos", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCajaEstatus(context) {
         try {
            let res = await axios.get("cajas/estatus");
            context.commit("estatus_caja", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRepartidores(context, payload) {
         try {
            if (!payload) {
               payload = context.state.repartidores.options;
            }
            let res = await axios.get("repas", {
               params: payload,
            });
            context.commit("repartidores", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getEmpresas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.empresas.options;
            }
            let res = await axios.get("empresas", {
               params: payload,
            });
            context.commit("empresas", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getClientes(context, payload) {
         try {
            if (!payload) {
               payload = context.state.clientes.options;
            }
            let res = await axios.get("clientes", {
               params: payload,
            });
            context.commit("clientes", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPedidos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.pedidos.options;
            }
            let res = await axios.get("pedidos", {
               params: payload,
            });
            context.commit("pedidos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPedidosRestas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.pedidos.options;
            }
            let res = await axios.get("resta/pedidos", {
               params: payload,
            });
            context.commit("pedidos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPedidosRepas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.pedidos.options;
            }
            let res = await axios.get("repa/pedidos", {
               params: payload,
            });
            context.commit("pedidos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCajas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.cajas.options;
            }
            let res = await axios.get("cajas", {
               params: payload,
            });
            context.commit("cajas", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            context.dispatch("getCajaEstatus");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCajaPreview(context, payload) {
         try {
            let res = await axios.get("/cajas/preview", {
               params: payload,
            });
            context.commit("caja_preview", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getMovimientos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.movimientos.options;
            }
            let res = await axios.get("movimientos", {
               params: payload,
            });
            context.commit("movimientos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPrestamosRepa(context, payload) {
         try {
            let res = await axios.get("movimientos/prestamos_repa", {
               params: payload,
            });
            return res.data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSaldo(context, payload) {
         try {
            let res = await axios.get("prestamos/saldo_repa", {
               params: payload,
            });
            return res.data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getFourU(context, payload) {
         try {
            if (!payload) {
               payload = context.state.four_u.options;
            }
            let res = await axios.get("fouru", {
               params: payload,
            });
            context.commit("four_u", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
               balance: res.data.balance,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPrestamos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.prestamos.options;
            }
            let res = await axios.get("prestamos", {
               params: payload,
            });
            context.commit("prestamos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSaldosPrestamos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.saldos_prestamos.options;
            }
            let res = await axios.get("saldos_prestamos", {
               params: payload,
            });
            context.commit("saldos_prestamos", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCajaGeneral(context, payload) {
         try {
            if (!payload) {
               payload = context.state.caja_general.options;
            }
            let res = await axios.get("caja_general", {
               params: payload,
            });
            context.commit("caja_general", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
               balance: res.data.balance,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPedidosRepa(context, payload) {
         try {
            let res = await axios.get("pedidos/repa", {
               params: payload,
            });
            context.commit("pedidos_repa", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPedidosEmpresa(context, payload) {
         try {
            let res = await axios.get("pedidos/empresa", {
               params: payload,
            });
            context.commit("pedidos_empresa", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRepartidoresAutocomplete(context, payload) {
         try {
            if (!payload) {
               return;
            }
            if (!payload.search) {
               return;
            }
            let res = await axios.get("repas/autocomplete", {
               params: payload,
            });
            context.commit("repartidores_autocomplete", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getEmpresasAutocomplete(context, payload) {
         try {
            if (!payload) {
               return;
            }
            if (!payload.search) {
               return;
            }
            let res = await axios.get("empresas/autocomplete", {
               params: payload,
            });
            context.commit("empresas_autocomplete", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getClientesAutocomplete(context, payload) {
         try {
            if (!payload) {
               return;
            }
            if (!payload.search) {
               return;
            }
            let res = await axios.get("clientes/autocomplete", {
               params: payload,
            });
            context.commit("clientes_autocomplete", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRestaClientesAutocomplete(context, payload) {
         try {
            if (!payload) {
               return;
            }
            if (!payload.search) {
               return;
            }
            let res = await axios.get("resta/clientes/autocomplete", {
               params: payload,
            });
            context.commit("clientes_autocomplete", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //POST
      async insertUser(context, payload) {
         try {
            await axios.post("users", payload);
            context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRol(context, payload) {
         try {
            await axios.post("roles", payload);
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertCliente(context, payload) {
         try {
            await axios.post("clientes", payload);
            context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRestaCliente(context, payload) {
         try {
            await axios.post("resta/clientes", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRepartidor(context, payload) {
         try {
            await axios.post("repas", payload);
            context.dispatch("getRepartidores");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertEmpresa(context, payload) {
         try {
            await axios.post("empresas", payload);
            context.dispatch("getEmpresas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertPedido(context, payload) {
         try {
            let res = await axios.post("pedidos", payload);
            context.dispatch("getPedidos");
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRestaPedido(context, payload) {
         try {
            let res = await axios.post("resta/pedidos", payload);
            context.dispatch("getPedidosRestas");
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async aperturaCaja(context, payload) {
         try {
            await axios.post("cajas", payload);
            context.dispatch("getCajas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async salidaCaja(context, payload) {
         try {
            await axios.post("movimientos/salida", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async entradaCaja(context, payload) {
         try {
            await axios.post("movimientos/entrada", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async prestamosPrestar(context, payload) {
         try {
            await axios.post("prestamos/prestar", payload);
            context.dispatch("getPrestamos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async prestamosCobrar(context, payload) {
         try {
            await axios.post("prestamos/cobrar", payload);
            context.dispatch("getPrestamos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async salidaFourU(context, payload) {
         try {
            await axios.post("fouru/salida", payload);
            context.dispatch("getFourU");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async entradaFourU(context, payload) {
         try {
            await axios.post("fouru/entrada", payload);
            context.dispatch("getFourU");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async salidaCajaGeneral(context, payload) {
         try {
            await axios.post("caja_general/salida", payload);
            context.dispatch("getCajaGeneral");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async entradaCajaGeneral(context, payload) {
         try {
            await axios.post("caja_general/entrada", payload);
            context.dispatch("getCajaGeneral");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async recibirRepa(context, payload) {
         try {
            await axios.post("movimientos/repa", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async entregarEmpresa(context, payload) {
         try {
            await axios.post("movimientos/empresa", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async prestamo(context, payload) {
         try {
            await axios.post("movimientos/prestamo", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async pagoPrestamo(context, payload) {
         try {
            await axios.post("movimientos/pagar_prestamo", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async pagoSemanal(context, payload) {
         try {
            await axios.post("movimientos/pago_semanal", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async pagoMensual(context, payload) {
         try {
            await axios.post("movimientos/pago_mensual", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async fondos(context, payload) {
         try {
            await axios.post("movimientos/fondos", payload);
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async registerRepaToken(context, payload) {
         try {
            await axios.post("repa/setToken", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //PUT
      async updateUser(context, payload) {
         try {
            await axios.put("users", payload);
            context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateRol(context, payload) {
         try {
            await axios.put("roles", payload);
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateCliente(context, payload) {
         try {
            await axios.put("clientes", payload);
            context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateRestaCliente(context, payload) {
         try {
            await axios.put("resta/clientes", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateRepartidor(context, payload) {
         try {
            await axios.put("repas", payload);
            context.dispatch("getRepartidores");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateEmpresa(context, payload) {
         try {
            await axios.put("empresas", payload);
            context.dispatch("getEmpresas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updatePedido(context, payload) {
         try {
            let res = await axios.put("pedidos", payload);
            context.dispatch("getPedidos");
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateRestaPedido(context, payload) {
         try {
            let res = await axios.put("resta/pedidos", payload);
            context.dispatch("getPedidosRestas");
            return res;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async verificarPedido(context, payload) {
         try {
            await axios.put("verificar", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },     
      async cierreCaja(context, payload) {
         try {
            await axios.put("cajas", payload);
            context.dispatch("getCajas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async changeRepaState(context, payload) {
         try {
            await axios.put("repa/pedidos", payload);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //DELETE
      async deleteRol(context, payload) {
         try {
            await axios.delete("roles", {
               data: {
                  rol_id: payload.rol_id,
               },
            });
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteCliente(context, payload) {
         try {
            console.log(payload);
            await axios.delete("clientes", {
               data: payload,
            });
            context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deletePedido(context, payload) {
         try {
            console.log(payload);
            await axios.delete("pedidos", {
               data: payload,
            });
            context.dispatch("getPedidos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cancelMovimiento(context, payload) {
         try {
            await axios.delete("movimientos", {
               data: payload,
            });
            context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cancelPrestamo(context, payload) {
         try {
            await axios.delete("prestamos", {
               data: payload,
            });
            context.dispatch("getPrestamos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteFourU(context, payload) {
         try {
            await axios.delete("fouru", {
               data: payload,
            });
            context.dispatch("getFourU");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteCajaGeneral(context, payload) {
         try {
            await axios.delete("caja_general", {
               data: payload,
            });
            context.dispatch("getCajaGeneral");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
   },
});
