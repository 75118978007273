<template>
   <v-footer>
      <v-spacer></v-spacer>
      <v-icon dense>mdi-copyright</v-icon>
      <v-btn @click="$router.push({ name: 'State' }, () => {})" icon elevation="0" x-small>-</v-btn>
      {{ app_name }}
      {{current_year}}
      <v-spacer></v-spacer>
   </v-footer>
</template>

<script>
import c from "../constant";
import moment from "moment";

export default {
   computed: {
      app_name: function () {
         return c.APP_NAME;
      },
      current_year: function(){
          return moment().format("YYYY");
      }
   },
};
</script>

<style></style>
