import Vue from "vue";
import VueRouter from "vue-router";
import c from "./constant";

Vue.use(VueRouter);

const routes = [
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Main.vue"),
      meta: {
         require_permisos: [],
         title: "Dashboard",
         icon: "mdi-home",
         show: false,
         user_type: "U"
      },
   },
   {
      path: "/",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
         require_permisos: [],
         title: "Login",
         icon: "mdi-login",
         show: false,
         user_type: "N"
      },
   },
   {
      path: "/state",
      name: "State",
      component: () => import("./views/State.vue"),
      meta: {
         require_permisos: [c.PERMISOS.super],
         title: "State",
         icon: "mdi-code-json",
         show: false,
         user_type: "U"
      },
   },
   {
      path: "/pedidos",
      name: "Pedidos",
      component: () => import("./views/Pedidos.vue"),
      meta: {
         require_permisos: [c.PERMISOS.pedidos],
         title: "Pedidos",
         icon: "mdi-food-fork-drink",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/repa/pedidos",
      name: "RepaPedidos",
      component: () => import("./views/RepaPedidos.vue"),
      meta: {
         require_permisos: ["repartidor"],
         title: "Pedidos",
         icon: "mdi-food-fork-drink",
         show: true,
         user_type: "R"
      },
   },
   {
      path: "/resta/pedidos",
      name: "RestaPedidos",
      component: () => import("./views/RestaPedidos.vue"),
      meta: {
         require_permisos: ["empresa"],
         title: "Pedidos",
         icon: "mdi-food-fork-drink",
         show: true,
         user_type: "E"
      },
   },
   {
      path: "/caja",
      name: "Cajas",
      component: () => import("./views/Cajas.vue"),
      meta: {
         require_permisos: [c.PERMISOS.cajas],
         title: "Cajas",
         icon: "mdi-cash-register",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/movimientos",
      name: "Movimientos",
      component: () => import("./views/Movimientos.vue"),
      meta: {
         require_permisos: [c.PERMISOS.movimientos],
         title: "Movimientos",
         icon: "mdi-swap-horizontal-bold",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/reportes",
      name: "Reportes",
      component: () => import("./views/Reportes.vue"),
      meta: {
         require_permisos: [c.PERMISOS.reportes],
         title: "Reportes",
         icon: "mdi-finance",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/four_u",
      name: "4U",
      component: () => import("./views/FourU.vue"),
      meta: {
         require_permisos: [c.PERMISOS.four_u],
         title: "Caja 4U",
         icon: "mdi-numeric-4-circle-outline",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/caja_general",
      name: "Caja General",
      component: () => import("./views/CajaGeneral.vue"),
      meta: {
         require_permisos: [c.PERMISOS.caja_general],
         title: "Caja General",
         icon: "mdi-cellphone-key",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/clientes",
      name: "Clientes",
      component: () => import("./views/Clientes.vue"),
      meta: {
         require_permisos: [c.PERMISOS.clientes],
         title: "Clientes",
         icon: "mdi-card-account-details",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/repas",
      name: "Repartidores",
      component: () => import("./views/Repartidores.vue"),
      meta: {
         require_permisos: [c.PERMISOS.repartidores],
         title: "Repartidores",
         icon: "mdi-moped",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/prestamos",
      name: "Prestamos",
      component: () => import("./views/Prestamos.vue"),
      meta: {
         require_permisos: [c.PERMISOS.seccion_prestamos],
         title: "Prestamos",
         icon: "mdi-credit-card-sync-outline",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/empresas",
      name: "Empresas",
      component: () => import("./views/Empresas.vue"),
      meta: {
         require_permisos: [c.PERMISOS.empresas],
         title: "Empresas",
         icon: "mdi-store",
         show: true,
         user_type: "U"
      },
   },
   {
      path: "/users",
      name: "Users",
      component: () => import("./views/Users.vue"),
      meta: {
         require_permisos: [c.PERMISOS.usuarios_roles],
         title: "Usuarios y Roles",
         icon: "mdi-account-group",
         show: true,
         user_type: "U"
      },
   },
];

const router = new VueRouter({
   routes,
});

export default router;
